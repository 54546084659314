import React, { useEffect, useState, useCallback, useRef } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import { useCart } from "../contexts/CartContext";
import {
  XCircleIcon,
  CheckCircleIcon,
  ShoppingBagIcon,
  CreditCardIcon,
  CalendarIcon,
  TruckIcon,
} from "@heroicons/react/24/outline";
import Lottie from "lottie-react";
import checkAnimation from "../assets/images/check-animation.json";

const isSuccessfulStatus = (status) => {
  return (
    status === "Success" || status === "authorized" || status === "captured"
  );
};

const formatCurrency = (amount, currency) => {
  if (amount === undefined || currency === undefined) {
    return "N/A";
  }
  return new Intl.NumberFormat("da-DK", {
    style: "currency",
    currency: currency,
  }).format(amount);
};

const OrderConfirmationPage = () => {
  const [orderStatus, setOrderStatus] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null);
  const [error, setError] = useState(null);
  const { orderNumber } = useParams();
  const location = useLocation();
  const { dispatch } = useCart();
  const updateInProgress = useRef(false);
  const hasUpdated = useRef(false);

  const fetchOrderStatus = useCallback(
    async (retryCount = 0) => {
      if (updateInProgress.current || hasUpdated.current) {
        console.log("Update already in progress or completed, skipping...");
        return false;
      }

      console.log("Fetching order status for order number:", orderNumber);
      const queryParams = new URLSearchParams(location.search);
      console.log("OnPay response:", Object.fromEntries(queryParams));

      updateInProgress.current = true;

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/update-order-status`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              orderNumber,
              status:
                queryParams.get("onpay_errorcode") === "0"
                  ? "Success"
                  : "Failed",
              onpayDetails: Object.fromEntries(queryParams),
            }),
          }
        );

        if (response.status === 404) {
          console.log("Order not found, retrying...");
          return false;
        }

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log("Received data from server:", data);

        setOrderStatus(data.status);
        setOrderDetails(data.orderDetails);
        hasUpdated.current = true;

        return true;
      } catch (error) {
        console.error("Error fetching order status:", error);
        return false;
      } finally {
        updateInProgress.current = false;
      }
    },
    [orderNumber, location.search]
  );

  useEffect(() => {
    let isMounted = true;
    let retryTimeout;

    const attemptFetch = async () => {
      if (!isMounted || hasUpdated.current) return;

      const success = await fetchOrderStatus();

      if (success || !isMounted) {
        if (retryTimeout) {
          clearTimeout(retryTimeout);
        }
      } else if (!hasUpdated.current) {
        retryTimeout = setTimeout(attemptFetch, 5000);
      }
    };

    attemptFetch();

    return () => {
      isMounted = false;
      if (retryTimeout) {
        clearTimeout(retryTimeout);
      }
    };
  }, [fetchOrderStatus]);

  if (error) {
    return (
      <div className="container mx-auto px-4 py-8 max-w-3xl">
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
          role="alert"
        >
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{error}</span>
        </div>
      </div>
    );
  }

  if (!orderStatus) {
    return (
      <div className="container mx-auto px-4 py-8 max-w-3xl">
        <div className="bg-cosmic-latte p-8 rounded-lg shadow-lg">
          <h1 className="text-3xl font-bold text-primary-green mb-4 text-center">
            Verificerer ordre...
          </h1>
          <div className="flex justify-center">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary-green"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 max-w-3xl">
      <div className="bg-cosmic-latte p-8 rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold text-primary-green mb-8 text-center">
          Ordre Bekræftelse
        </h1>
        {error && (
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-6"
            role="alert"
          >
            <strong className="font-bold">Fejl: </strong>
            <span className="block sm:inline">{error}</span>
          </div>
        )}
        {!orderDetails && !error && (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-primary-green"></div>
          </div>
        )}
        {orderDetails && (
          <div className="space-y-6">
            <div className="flex justify-center">
              {isSuccessfulStatus(orderStatus) ? (
                <Lottie
                  animationData={checkAnimation}
                  loop={false}
                  style={{ width: 150, height: 150 }}
                />
              ) : (
                <XCircleIcon className="h-24 w-24 text-red-500" />
              )}
            </div>
            <h2 className="text-2xl font-semibold text-center mb-6">
              {isSuccessfulStatus(orderStatus)
                ? "Tak for dit køb!"
                : "Betaling mislykkedes"}
            </h2>
            <div className="p-6 rounded-lg shadow-inner bg-white">
              <div className="space-y-4">
                <div className="flex items-center">
                  <ShoppingBagIcon className="h-6 w-6 text-forest-green mr-2" />
                  <span className="text-gray-600 mr-2">Ordrenummer:</span>
                  <span className="font-semibold">
                    {orderDetails.orderNumber}
                  </span>
                </div>
                <div className="flex items-center">
                  <CreditCardIcon className="h-6 w-6 text-forest-green mr-2" />
                  <span className="text-gray-600 mr-2">Beløb:</span>
                  <span className="font-semibold">
                    {formatCurrency(
                      orderDetails.totalAmount,
                      orderDetails.currency
                    )}
                  </span>
                </div>
                <div className="flex items-center">
                  <CalendarIcon className="h-6 w-6 text-forest-green mr-2" />
                  <span className="text-gray-600 mr-2">Dato:</span>
                  <span className="font-semibold">
                    {new Date(orderDetails.createdAt).toLocaleDateString(
                      "da-DK"
                    )}
                  </span>
                </div>
                <div className="flex items-center">
                  <CreditCardIcon className="h-6 w-6 text-forest-green mr-2" />
                  <span className="text-gray-600 mr-2">Betalingsmetode:</span>
                  <span className="font-semibold">
                    {orderDetails.paymentMethod}
                  </span>
                </div>
              </div>
            </div>
            {orderDetails.purchasedItems &&
              orderDetails.purchasedItems.length > 0 && (
                <div className="mt-8">
                  <h3 className="text-xl font-semibold mb-4">Købte varer:</h3>
                  <ul className="space-y-2">
                    {orderDetails.purchasedItems.map((item, index) => (
                      <li
                        key={index}
                        className="flex justify-between items-center"
                      >
                        <span>
                          {item.productName} x {item.quantity}
                        </span>
                        <span>
                          {formatCurrency(
                            item.price * item.quantity,
                            orderDetails.currency
                          )}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            {orderStatus === "Success" && (
              <div className="mt-8 space-y-4">
                <div className="flex items-center">
                  <TruckIcon className="h-6 w-6 text-forest-green mr-2" />
                  <span className="text-gray-600">
                    Forventet leveringstid: 3-5 hverdage
                  </span>
                </div>
                <p className="text-gray-600">
                  En bekræftelsesmail er sendt til din e-mailadresse med
                  ordredetaljer.
                </p>
              </div>
            )}
            <div className="mt-8 flex justify-center">
              {isSuccessfulStatus(orderStatus) ? (
                <Link
                  to="/"
                  className="bg-green-button-color text-cosmic-latte px-6 py-2 rounded-lg hover:bg-opacity-90 transition-colors duration-200 text-lg font-semibold"
                >
                  Fortsæt med at handle
                </Link>
              ) : (
                <Link
                  to="/checkout"
                  className="bg-red-500 text-white px-6 py-2 rounded-lg hover:bg-opacity-90 transition-colors duration-200 text-lg font-semibold"
                >
                  Prøv igen
                </Link>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderConfirmationPage;
