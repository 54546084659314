import React, { useState, useEffect } from 'react';
import { fetchPurchaseHistory } from '../../services/purchaseService';
import { format } from 'date-fns';
import { da } from 'date-fns/locale';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';

const PurchaseHistory = ({ userId }) => {
  const [purchases, setPurchases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedOrder, setExpandedOrder] = useState(null);

  useEffect(() => {
    const loadPurchases = async () => {
      try {
        const history = await fetchPurchaseHistory(userId);
        setPurchases(history);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching purchase history:', err);
        setError(err.message);
        setLoading(false);
      }
    };
    loadPurchases();
  }, [userId]);

  const toggleOrderDetails = (orderId) => {
    setExpandedOrder(expandedOrder === orderId ? null : orderId);
  };

  if (loading) return <div className="text-center py-4">Indlæser købshistorik...</div>;
  if (error) return <div className="text-center py-4 text-red-500">Fejl: {error}</div>;

  return (
    <div className="bg-cosmic-latte rounded-lg shadow-md p-6">
      <h2 className="text-2xl font-semibold text-primary-green mb-4">Købshistorik</h2>
      {purchases.length === 0 ? (
        <p>Du har endnu ikke foretaget nogen køb.</p>
      ) : (
        <ul className="space-y-6">
          {purchases.map((purchase) => (
            <li key={purchase._id} className="border-b border-gray-200 pb-4">
              <div 
                className="flex justify-between items-center cursor-pointer"
                onClick={() => toggleOrderDetails(purchase._id)}
              >
                <div>
                  <h3 className="text-xl font-semibold text-primary-green">Ordre #{purchase.orderNumber}</h3>
                  <p className="text-gray-600">
                    {format(new Date(purchase.date), "d. MMMM yyyy 'kl.' HH:mm", { locale: da })}
                  </p>
                </div>
                <div className="flex items-center">
                  <span className="font-semibold mr-2">{purchase.total.toFixed(2)} DKK</span>
                  {expandedOrder === purchase._id ? (
                    <ChevronUpIcon className="h-5 w-5 text-primary-green" />
                  ) : (
                    <ChevronDownIcon className="h-5 w-5 text-primary-green" />
                  )}
                </div>
              </div>
              
              {expandedOrder === purchase._id && (
                <div className="mt-4 pl-4">
                  <h4 className="font-semibold mb-2">Produkter:</h4>
                  <ul className="list-disc pl-5 mb-4">
                    {purchase.items.map((item, index) => (
                      <li key={index} className="mb-1">
                        {item.product.name} - Antal: {item.quantity}, Pris: {item.price.toFixed(2)} DKK
                      </li>
                    ))}
                  </ul>
                  
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <h4 className="font-semibold mb-2">Leveringsadresse:</h4>
                      <p>{purchase.shippingAddress.name}</p>
                      <p>{purchase.shippingAddress.address}</p>
                      <p>{purchase.shippingAddress.postalCode} {purchase.shippingAddress.city}</p>
                      <p>{purchase.shippingAddress.country}</p>
                    </div>
                    
                    <div>
                      <h4 className="font-semibold mb-2">Faktureringsadresse:</h4>
                      <p>{purchase.billingAddress.name}</p>
                      <p>{purchase.billingAddress.address}</p>
                      <p>{purchase.billingAddress.postalCode} {purchase.billingAddress.city}</p>
                      <p>{purchase.billingAddress.country}</p>
                    </div>
                  </div>
                </div>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default PurchaseHistory;