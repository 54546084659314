import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

// Helper function to get token
const getToken = async () => {
  const token = await localStorage.getItem("auth0Token");
  return token;
};

export const fetchUserPosts = async () => {
  try {
    const response = await axios.get(`${API_URL}/api/user-posts`);
    return response.data;
  } catch (error) {
    console.error("Error fetching user posts:", error);
    throw new Error("Failed to fetch user posts");
  }
};

export const fetchUserPostsByUserId = async (userId) => {
  try {
    const token = await getToken();
    const response = await axios.get(
      `${API_URL}/api/user-posts/user/${userId}`,
      {
        headers: token ? { Authorization: `Bearer ${token}` } : {},
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching user posts:", error);
    throw new Error("Failed to fetch user posts");
  }
};

export const createUserPost = async (postData, token) => {
  try {
    if (!token) {
      throw new Error("No authentication token available");
    }

    console.log("Creating user post with token present");

    const formData = new FormData();

    // Add basic fields
    formData.append("title", postData.title);
    formData.append("description", postData.description);
    formData.append("price", postData.price);
    formData.append("contactInfo", JSON.stringify(postData.contactInfo));
    formData.append("userId", postData.userId); // Add user ID

    // Add images
    postData.images.forEach((image) => {
      formData.append("images", image.file);
    });

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };

    console.log("Request config:", {
      url: `${API_URL}/api/user-posts`,
      method: "POST",
      headers: config.headers,
    });

    const response = await axios.post(
      `${API_URL}/api/user-posts`,
      formData,
      config
    );

    return response.data;
  } catch (error) {
    console.error("Detailed service error:", {
      message: error.message,
      response: error.response?.data,
      status: error.response?.status,
      config: {
        url: error.config?.url,
        method: error.config?.method,
        headers: error.config?.headers,
      },
    });

    if (error.response?.status === 401) {
      throw new Error("Authentication failed: Please log in again");
    }
    if (error.response?.status === 500) {
      throw new Error(
        `Server error: ${error.response.data.message || "Unknown error"}`
      );
    }
    throw new Error("Failed to create user post");
  }
};

export const updateUserPost = async (postId, postData) => {
  try {
    const token = await getToken();
    if (!token) {
      throw new Error("No authentication token available");
    }

    const formData = new FormData();

    // Add basic fields
    formData.append("title", postData.title);
    formData.append("description", postData.description);
    formData.append("price", postData.price);
    formData.append("contactInfo", JSON.stringify(postData.contactInfo));

    // Add images if they exist
    if (postData.images) {
      postData.images.forEach((image) => {
        if (image.file) {
          formData.append("images", image.file);
        }
      });
    }

    const response = await axios.patch(
      `${API_URL}/api/user-posts/${postId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error updating user post:", error);
    throw new Error("Failed to update user post");
  }
};

export const deleteUserPost = async (postId) => {
  try {
    const token = await getToken();
    if (!token) {
      throw new Error("No authentication token available");
    }

    await axios.delete(`${API_URL}/api/user-posts/${postId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error("Error deleting user post:", error);
    throw new Error("Failed to delete user post");
  }
};

export const getPostById = async (postId) => {
  try {
    const response = await axios.get(`${API_URL}/api/user-posts/${postId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching post:", error);
    throw new Error("Failed to fetch post");
  }
};
