// src/services/purchaseService.js

export const createOrUpdatePurchase = async (purchaseData) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/purchases`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(purchaseData),
    });
    if (!response.ok) {
      throw new Error('Failed to create/update purchase');
    }
    return await response.json();
  } catch (error) {
    console.error("Error creating/updating purchase:", error);
    throw new Error("Failed to create/update purchase");
  }
};

export const fetchPurchaseHistory = async (userId) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/purchases/${userId}`);
    if (!response.ok) {
      throw new Error('Failed to fetch purchase history');
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching purchase history:", error);
    throw new Error("Failed to fetch purchase history");
  }
};