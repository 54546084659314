import React from "react";
import { PortableText } from "@portabletext/react";
import { imageUrl } from '../../services/sanityClient';

function BlogPost({ post }) {
  if (!post) return null;

  const portableTextComponents = {
    types: {
      image: ({ value }) => {
        if (!value?.asset?._ref) {
          return null;
        }
        return (
          <img
            alt={value.alt || " "}
            loading="lazy"
            src={imageUrl(value)
              .width(320)
              .height(240)
              .fit("max")
              .auto("format")
              .url()}
          />
        );
      },
    },
  };

  return (
    <article className="bg-cosmic-latte rounded-2xl overflow-hidden max-w-4xl mx-auto shadow-lg">
      {post.imageUrl && (
        <img
          src={post.imageUrl}
          alt={post.title}
          className="w-full h-64 object-cover"
        />
      )}
      <div className="px-6 sm:px-8 py-8">
        <header className="mb-8">
          <h1 className="text-4xl font-bold text-primary-green mb-4">
            {post.title}
          </h1>
          <div className="flex items-center text-gray-600 mb-4">
            {post.authorImage && (
              <img
                src={post.authorImage}
                alt={post.name}
                className="w-10 h-10 rounded-full mr-4"
              />
            )}
            <span className="mr-4">{post.name}</span>
            <span>{new Date(post.publishedAt).toLocaleDateString()}</span>
          </div>
          {post.categories && (
            <div className="flex flex-wrap gap-2 mb-4">
              {post.categories.map((category) => (
                <span
                  key={category}
                  className="bg-sage text-primary-green px-3 py-1 rounded-full text-sm"
                >
                  {category}
                </span>
              ))}
            </div>
          )}
        </header>

        <div className="prose max-w-none">
          <PortableText value={post.body} components={portableTextComponents} />
        </div>
      </div>
    </article>
  );
}

export default BlogPost;
