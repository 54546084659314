import React, { useState, useEffect, useCallback } from "react";
import { useCart } from "../contexts/CartContext";
import { useAuth0 } from "@auth0/auth0-react";
import CartReview from "../components/Checkout/CartReview";
import ShippingForm from "../components/Checkout/ShippingForm";
import OrderConfirmation from "../components/Checkout/OrderConfirmation";
import LoginPrompt from "../components/Checkout/LoginPrompt";
import { useNavigate } from "react-router-dom";
import { fetchUser } from "../services/api";
import { debounce } from "lodash";
import { v4 as uuidv4 } from "uuid";

const STORAGE_KEY = "weLoveBirdsCheckoutInfo";

const CheckoutPage = () => {
  const { state: cartState, dispatch } = useCart();
  const { isAuthenticated, user } = useAuth0();
  const [step, setStep] = useState(1);
  const [userInfo, setUserInfo] = useState(null);
  const [shippingSettings, setShippingSettings] = useState({
    shippingCost: 0,
    enableFreeShipping: false,
    freeShippingThreshold: 0,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [shippingInfo, setShippingInfo] = useState(() => {
    const savedInfo = localStorage.getItem(STORAGE_KEY);
    return savedInfo
      ? JSON.parse(savedInfo)
      : {
          fullName: "",
          address: "",
          city: "",
          postalCode: "",
          country: "Danmark",
          email: "",
          sameAsBilling: true,
          billingAddress: "",
          billingCity: "",
          billingPostalCode: "",
          billingCountry: "Danmark",
        };
  });
  const [onPayParams, setOnPayParams] = useState(null);

  useEffect(() => {
    if (isAuthenticated && user) {
      setShippingInfo((prevInfo) => ({
        ...prevInfo,
        fullName: prevInfo.fullName,
        email: user.email || prevInfo.email,
      }));
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(shippingInfo));
  }, [shippingInfo]);

  useEffect(() => {
    const loadUserData = async () => {
      if (isAuthenticated && user?.sub) {
        try {
          const userData = await fetchUser(user.sub);
          setUserInfo({
            preferredUsername: userData.username || user.nickname,
            email: user.email,
          });
        } catch (error) {
          console.error("Error fetching user data:", error);
          setUserInfo({
            preferredUsername: user.nickname,
            email: user.email,
          });
        }
      }
    };

    loadUserData();
  }, [isAuthenticated, user]);

  useEffect(() => {
    const fetchShippingSettings = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/shipping-settings`
        );
        const data = await response.json();
        setShippingSettings(data);
      } catch (error) {
        console.error("Error fetching shipping settings:", error);
      }
    };

    fetchShippingSettings();
  }, []);

  const totalPrice = cartState.items.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  // Beregn fragtpris baseret på indstillinger og ordretotal
  const calculateShippingCost = useCallback(() => {
    if (
      totalPrice >= shippingSettings.freeShippingThreshold &&
      shippingSettings.freeShippingThreshold > 0
    ) {
      return 0;
    }
    return shippingSettings.shippingCost;
  }, [totalPrice, shippingSettings]);

  const shippingCost = calculateShippingCost();
  const totalWithShipping = totalPrice + shippingCost;

  const handleShippingInfoChange = (e) => {
    const { name, value, type, checked } = e.target;
    setShippingInfo((prevInfo) => ({
      ...prevInfo,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const debouncedPreparePayment = useCallback(
    debounce(async (orderData) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/prepare-payment`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(orderData),
          }
        );

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Failed to prepare payment");
        }

        const data = await response.json();
        setOnPayParams(data);
        return true;
      } catch (error) {
        console.error("Error during preparePayment:", error);
        alert(
          "Der opstod en fejl ved forberedelse af betalingen. Prøv venligst igen."
        );
        return false;
      }
    }, 500),
    [setOnPayParams]
  );

  const handleNextStep = async () => {
    if (isSubmitting) return;

    if (step === 2 && !isAuthenticated) {
      alert("Du skal være logget ind for at fortsætte til betaling.");
      return;
    }

    if (step === 2) {
      setIsSubmitting(true);
      try {
        const orderNumber = `ORDER-${Date.now()}`;

        // Opret billing information baseret på kundens valg
        const billingInfo = shippingInfo.sameAsBilling
          ? {
              fullName: shippingInfo.fullName,
              address: shippingInfo.address,
              city: shippingInfo.city,
              postalCode: shippingInfo.postalCode,
              email: shippingInfo.email,
              country: shippingInfo.country,
            }
          : {
              fullName: shippingInfo.billingFullName,
              address: shippingInfo.billingAddress,
              city: shippingInfo.billingCity,
              postalCode: shippingInfo.billingPostalCode,
              email: shippingInfo.email,
              country: "Danmark",
            };

        const orderData = {
          totalWithShipping,
          orderNumber,
          items: cartState.items.map((item) => ({
            _key: uuidv4(),
            productId: item.id,
            productName: item.name,
            quantity: item.quantity,
            price: item.price,
          })),
          userId: user.sub,
          shippingInfo: {
            fullName: shippingInfo.fullName,
            address: shippingInfo.address,
            city: shippingInfo.city,
            postalCode: shippingInfo.postalCode,
            email: shippingInfo.email,
            country: shippingInfo.country,
          },
          billingInfo, // Tilføj billingInfo
          sameAsShipping: shippingInfo.sameAsBilling, // Gem brugerens valg
          createdAt: new Date().toISOString(),
          expiresAt: new Date(Date.now() + 30 * 60 * 1000).toISOString(),
          status: "pending",
        };

        const paymentResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/api/prepare-payment`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(orderData),
          }
        );

        if (!paymentResponse.ok) {
          throw new Error("Failed to prepare payment");
        }

        const paymentData = await paymentResponse.json();
        setOnPayParams(paymentData);
        setStep(step + 1);
      } catch (error) {
        console.error("Error in checkout process:", error);
        alert("Der opstod en fejl. Prøv venligst igen.");
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setStep(step + 1);
    }
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handlePurchaseComplete = async (paymentResult) => {
    if (paymentResult.success) {
      try {
        const purchaseData = {
          userId: user.sub,
          orderNumber: onPayParams.reference,
          items: cartState.items,
          total: totalWithShipping,
          shippingAddress: shippingInfo,
          billingAddress: shippingInfo.sameAsBilling
            ? shippingInfo
            : {
                fullName: shippingInfo.billingFullName,
                address: shippingInfo.billingAddress,
                city: shippingInfo.billingCity,
                postalCode: shippingInfo.billingPostalCode,
                country: shippingInfo.billingCountry,
              },
        };

        // Clear the cart
        dispatch({ type: "CLEAR_CART" });
        // Redirect to order confirmation page or show success message
        navigate("/order-confirmation", {
          state: { orderNumber: purchaseData.orderNumber },
        });
      } catch (error) {
        console.error("Failed to handle purchase completion:", error);
      }
    } else {
      console.error("Payment failed:", paymentResult.error);
    }
  };

  const preparePayment = async (orderNumber) => {
    try {
      if (!user?.sub) {
        console.error("No user ID available");
        return false;
      }

      const orderData = {
        totalWithShipping,
        orderNumber,
        items: cartState.items.map((item) => ({
          productId: item.id,
          productName: item.name,
          quantity: item.quantity,
          price: item.price,
        })),
        userId: user.sub,
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/prepare-payment`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(orderData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to prepare payment");
      }

      const data = await response.json();
      setOnPayParams(data);
      return true;
    } catch (error) {
      console.error("Error during preparePayment:", error);
      alert(
        "Der opstod en fejl ved forberedelse af betalingen. Prøv venligst igen."
      );
      return false;
    }
  };

  const renderStepIndicator = () => (
    <div className="flex justify-center mb-8">
      {[1, 2, 3].map((stepNumber) => (
        <div key={stepNumber} className="flex items-center">
          <div
            className={`w-8 h-8 rounded-full flex items-center justify-center ${
              step >= stepNumber
                ? "bg-green-button-color text-cosmic-latte"
                : "bg-gray-200 text-gray-600"
            }`}
          >
            {stepNumber}
          </div>
          {stepNumber < 3 && (
            <div
              className={`w-16 h-1 ${
                step > stepNumber ? "bg-green-button-color" : "bg-gray-200"
              }`}
            />
          )}
        </div>
      ))}
    </div>
  );

  return (
    <div className="container mx-auto px-4 py-8 max-w-5xl">
      <h1 className="text-3xl font-bold text-primary-green mb-8 text-center">
        Checkout
      </h1>
      {renderStepIndicator()}
      {step === 1 && (
        <div className="bg-cosmic-latte p-6 rounded-lg shadow-md">
          <CartReview
            cartItems={cartState.items}
            dispatch={dispatch}
            handleNextStep={handleNextStep}
            shippingCost={shippingSettings.shippingCost}
            enableFreeShipping={shippingSettings.enableFreeShipping}
            freeShippingThreshold={shippingSettings.freeShippingThreshold}
          />
        </div>
      )}
      {step === 2 && (
        <>
          {isAuthenticated ? (
            <ShippingForm
              shippingInfo={shippingInfo}
              handleShippingInfoChange={handleShippingInfoChange}
              handleNextStep={handleNextStep}
              handlePreviousStep={handlePreviousStep}
              userInfo={userInfo}
              isSubmitting={isSubmitting} // Add this prop
            />
          ) : (
            <LoginPrompt />
          )}
        </>
      )}
      {step === 3 && (
        <OrderConfirmation
          shippingInfo={shippingInfo}
          cartItems={cartState.items}
          totalPrice={totalPrice}
          shippingCost={shippingCost}
          totalWithShipping={totalWithShipping}
          onPayParams={onPayParams}
          handlePreviousStep={handlePreviousStep}
          onPurchaseComplete={handlePurchaseComplete}
        />
      )}
    </div>
  );
};

export default CheckoutPage;
