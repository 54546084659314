import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { LockClosedIcon, UserPlusIcon } from "@heroicons/react/24/outline";

const LoginPrompt = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = () => {
    loginWithRedirect({
      appState: { returnTo: "/checkout" }
    });
  };

  const handleRegister = () => {
    loginWithRedirect({
      screen_hint: "signup",
      appState: { returnTo: "/checkout" }
    });
  };

  return (
    <div className="bg-cosmic-latte p-8 rounded-lg shadow-lg max-w-md mx-auto">
      <h2 className="text-2xl font-semibold text-primary-green mb-6 text-center">Log ind for at fortsætte</h2>
      
      <div className="space-y-4">
        <button
          onClick={handleLogin}
          className="w-full bg-green-button-color text-cosmic-latte px-6 py-3 rounded-lg hover:bg-opacity-90 transition-colors duration-200 text-lg font-semibold flex items-center justify-center"
        >
          <LockClosedIcon className="h-5 w-5 mr-2" />
          Log ind
        </button>
        
        <button
          onClick={handleRegister}
          className="w-full bg-light-green-50 text-primary-green px-6 py-3 rounded-lg hover:bg-opacity-90 transition-colors duration-200 text-lg font-semibold flex items-center justify-center border-2 border-primary-green"
        >
          <UserPlusIcon className="h-5 w-5 mr-2" />
          Opret ny konto
        </button>
      </div>
      
      <p className="mt-6 text-sm text-gray-600 text-center">
        Du skal være logget ind for at gennemføre dit køb. 
        Hvis du ikke har en konto, kan du nemt oprette en.
      </p>
    </div>
  );
};

export default LoginPrompt;