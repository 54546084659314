import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { deleteUser } from "../../services/api";

const DeleteAccount = () => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState(null);
  const { logout, user } = useAuth0();

  const handleDeleteAccount = async () => {
    if (!user?.sub) {
      setError("Bruger ID ikke fundet. Prøv at logge ind igen.");
      return;
    }

    setIsDeleting(true);
    setError(null);

    try {
      const response = await deleteUser(user.sub);

      if (response.status === "success") {
        logout({
          returnTo: window.location.origin,
          federated: true,
        });
      } else {
        throw new Error(response.message || "Fejl ved sletning af konto");
      }
    } catch (err) {
      console.error("Error deleting account:", err);
      setError(
        "Der opstod en fejl under sletning af din konto. " +
          "Prøv igen senere eller kontakt support hvis problemet fortsætter."
      );
      setIsDeleting(false);
    }
  };

  return (
    <div>
      {!showConfirmation ? (
        <button
          onClick={() => setShowConfirmation(true)}
          className="border-2 border-red-500 text-red-500 px-4 py-2 rounded-lg hover:bg-red-500 hover:text-cosmic-latte transition-colors duration-200 flex items-center"
        >
          <ExclamationTriangleIcon className="h-5 w-5 mr-2" />
          Slet min konto
        </button>
      ) : (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-6 py-4 rounded-lg relative"
          role="alert"
        >
          <div className="flex items-center mb-4">
            <ExclamationTriangleIcon className="h-6 w-6 mr-2" />
            <strong className="font-bold text-lg">Advarsel!</strong>
          </div>

          <div className="mb-4">
            <p className="mb-2">
              Er du sikker på, at du vil slette din konto? Dette vil:
            </p>
            <ul className="list-disc ml-6">
              <li>Slette alle dine opslag</li>
              <li>Fjerne dine personlige oplysninger</li>
              <li>Permanent slette din konto</li>
            </ul>
            <p className="mt-2">Denne handling kan ikke fortrydes!</p>
          </div>

          {error && (
            <div className="bg-red-200 text-red-800 p-3 rounded-lg mb-4">
              {error}
            </div>
          )}

          <div className="flex justify-end space-x-4">
            <button
              onClick={() => setShowConfirmation(false)}
              className="bg-gray-300 text-gray-800 px-4 py-2 rounded-lg hover:bg-gray-400 transition-colors duration-200"
              disabled={isDeleting}
            >
              Annuller
            </button>
            <button
              onClick={handleDeleteAccount}
              className="bg-red-500 text-cosmic-latte px-4 py-2 rounded-lg hover:bg-red-600 transition-colors duration-200"
              disabled={isDeleting}
            >
              {isDeleting ? "Sletter..." : "Ja, slet min konto"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeleteAccount;
