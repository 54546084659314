import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../services/sanityClient";
import { PortableText } from '@portabletext/react';
import { imageUrl } from '../services/sanityClient';

function ZooSponsorPage() {
  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "zooSponsor"][0]{
          title,
          subtitle,
          "heroImageUrl": heroImage.asset->url,
          introductionSection,
          factsSection,
          conservationSection,
          "zooLogoUrl": zooLogo.asset->url,
          ctaText,
          ctaLink
        }`
      )
      .then((data) => setPageData(data))
      .catch(console.error);
  }, []);

  if (!pageData) return <div>Loading...</div>;

  return (
    <div className="min-h-screen p-4 sm:p-8 rounded-3xl">
      <div className="bg-cosmic-latte max-w-6xl mx-auto rounded-3xl shadow-lg overflow-hidden">
        {/* Hero Section */}
        <div className="bg-light-green-50 py-20">
          <div className="container mx-auto px-8 text-center">
            <h1 className="text-5xl font-bold mb-4 text-primary-green">
              {pageData.title}
            </h1>
            <p className="text-2xl text-forest-green">{pageData.subtitle}</p>
          </div>
        </div>

        <div className="container mx-auto px-8 py-12">
          {/* Introduction Section */}
          <section className="mb-20 flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 md:pr-8 mb-8 md:mb-0">
              <h2 className="text-3xl font-semibold text-primary-green mb-4">
                {pageData.introductionSection.heading}
              </h2>
              <div className="prose">
                <PortableText value={pageData.introductionSection.content} />
              </div>
            </div>
            <div className="md:w-1/2">
              <img
                src={imageUrl(pageData.introductionSection.image).width(400).height(300).url()}
                alt="Introduction"
                className="w-full h-64 object-cover rounded-lg shadow-md"
              />
            </div>
          </section>

          {/* Facts Section */}
          <section className="mb-20 flex flex-col md:flex-row-reverse items-center">
            <div className="md:w-1/2 md:pl-8 mb-8 md:mb-0">
              <h2 className="text-3xl font-semibold text-primary-green mb-4">
                {pageData.factsSection.heading}
              </h2>
              <ul className="list-disc list-inside space-y-2 text-lg">
                {pageData.factsSection.facts.map((fact, index) => (
                  <li key={index}>{fact}</li>
                ))}
              </ul>
            </div>
            <div className="md:w-1/2">
              <img
                src={imageUrl(pageData.factsSection.image).width(400).height(300).url()}
                alt="Facts"
                className="w-full h-64 object-cover rounded-lg shadow-md"
              />
            </div>
          </section>

          {/* Conservation Efforts Section */}
          <section className="mb-20">
            <h2 className="text-3xl font-semibold text-primary-green mb-4">
              {pageData.conservationSection.heading}
            </h2>
            <div className="prose mb-4">
              <PortableText value={pageData.conservationSection.content} />
            </div>
            <ul className="list-disc list-inside space-y-2 text-lg mb-4">
              {pageData.conservationSection.efforts.map((effort, index) => (
                <li key={index}>{effort}</li>
              ))}
            </ul>
          </section>

          {/* Zoo Logo and Call to Action */}
          <section className="text-center mb-12">
            <img
              src={pageData.zooLogoUrl}
              alt="Zoo Logo"
              className="w-48 h-24 mx-auto object-contain mb-8"
            />
            <Link
              to={pageData.ctaLink}
              className="bg-green-button-color text-cosmic-latte px-8 py-4 rounded-lg hover:bg-opacity-90 transition-colors duration-200 text-xl font-bold inline-block shadow-lg"
            >
              {pageData.ctaText}
            </Link>
          </section>
        </div>
      </div>
    </div>
  );
}

export default ZooSponsorPage;