import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";

const RegisterPage = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  if (isAuthenticated) {
    return (
      <div className="max-w-md mx-auto mt-10 bg-cosmic-latte p-8 rounded-lg shadow-lg">
        <h2 className="text-3xl font-bold text-primary-green mb-6 text-center">
          Du er allerede registreret
        </h2>
        <p className="text-center mb-4">
          Du har allerede en konto og er logget ind.
        </p>
        <Link
          to="/"
          className="block text-center text-primary-green hover:underline"
        >
          Gå til forsiden
        </Link>
      </div>
    );
  }

  return (
    <div className="max-w-md mx-auto mt-10 bg-cosmic-latte p-8 rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold text-primary-green mb-6 text-center">
        Opret profil
      </h2>
      <button
        onClick={() => loginWithRedirect({ screen_hint: "signup" })}
        className="w-full bg-green-button-color text-cosmic-latte px-4 py-2 rounded-md hover:bg-opacity-90 transition-colors duration-200"
      >
        Opret profil med Auth0
      </button>
      <div className="mt-4 text-center">
        <Link to="/login" className="text-primary-green hover:underline">
          Har du allerede en konto? Log ind her
        </Link>
      </div>
    </div>
  );
};

export default RegisterPage;
