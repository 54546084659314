import React from "react";
import {
  PlusIcon,
  MinusIcon,
  TrashIcon,
  ArrowRightIcon,
  TruckIcon,
} from "@heroicons/react/24/outline";
import RoundButton from "./RoundButton";

const CartReview = ({
  cartItems,
  dispatch,
  handleNextStep,
  shippingCost,
  enableFreeShipping,
  freeShippingThreshold,
}) => {
  const updateQuantity = (id, newQuantity) => {
    if (newQuantity === 0) {
      dispatch({ type: "REMOVE_ITEM", payload: id });
    } else {
      dispatch({
        type: "UPDATE_QUANTITY",
        payload: { id, quantity: newQuantity },
      });
    }
  };

  const removeItem = (id) => {
    dispatch({ type: "REMOVE_ITEM", payload: id });
  };

  const totalPrice = cartItems.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  // Kun beregn remaining hvis gratis fragt er aktiveret
  const remainingForFreeShipping = enableFreeShipping
    ? Math.max(0, freeShippingThreshold - totalPrice)
    : 0;

  // Beregn den endelige fragtpris
  const finalShippingCost =
    enableFreeShipping && totalPrice >= freeShippingThreshold
      ? 0
      : shippingCost;

  return (
    <div>
      <h2 className="text-2xl font-semibold text-primary-green mb-4">
        Gennemgå din indkøbskurv
      </h2>
      {cartItems.length === 0 ? (
        <div className="text-center py-8">
          <p className="text-lg text-gray-600 mb-4">Din indkøbskurv er tom.</p>
          <p className="text-sm text-gray-500">
            Tilføj nogle produkter for at fortsætte med dit køb.
          </p>
        </div>
      ) : (
        <>
          {cartItems.map((item) => (
            <div
              key={item.id}
              className="flex items-center justify-between mb-4 pb-4 border-b border-gray-200"
            >
              <div className="flex items-center">
                <img
                  src={item.imageUrl}
                  alt={item.name}
                  className="w-16 h-16 object-cover rounded-md mr-4"
                />
                <div>
                  <h3 className="font-semibold text-gray-800">{item.name}</h3>
                  <p className="text-primary-green">
                    {item.price.toFixed(2)} DKK
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                {/* Desktop version */}
                <div className="hidden sm:flex items-center">
                  <button
                    onClick={() => updateQuantity(item.id, item.quantity - 1)}
                    className="bg-light-green-50 text-primary-green rounded-full p-2 hover:bg-green-100 transition-colors duration-200"
                    aria-label="Decrease quantity"
                  >
                    <MinusIcon className="h-5 w-5" />
                  </button>
                  <span className="mx-3 font-semibold min-w-[2rem] text-center">
                    {item.quantity}
                  </span>
                  <button
                    onClick={() => updateQuantity(item.id, item.quantity + 1)}
                    className="bg-light-green-50 text-primary-green rounded-full p-2 hover:bg-green-100 transition-colors duration-200"
                    aria-label="Increase quantity"
                  >
                    <PlusIcon className="h-5 w-5" />
                  </button>
                  <button
                    onClick={() => removeItem(item.id)}
                    className="ml-4 bg-red-100 text-red-500 rounded-full p-2 hover:bg-red-200 transition-colors duration-200"
                    aria-label="Remove item"
                  >
                    <TrashIcon className="h-5 w-5" />
                  </button>
                </div>
                {/* Mobile version */}
                <div className="flex sm:hidden items-center">
                  <button
                    onClick={() => updateQuantity(item.id, item.quantity - 1)}
                    className="bg-light-green-50 text-primary-green rounded-full p-1 hover:bg-green-100 transition-colors duration-200"
                    aria-label="Decrease quantity"
                  >
                    <MinusIcon className="h-4 w-4" />
                  </button>
                  <span className="mx-2 text-sm font-semibold min-w-[1.5rem] text-center">
                    {item.quantity}
                  </span>
                  <button
                    onClick={() => updateQuantity(item.id, item.quantity + 1)}
                    className="bg-light-green-50 text-primary-green rounded-full p-1 hover:bg-green-100 transition-colors duration-200"
                    aria-label="Increase quantity"
                  >
                    <PlusIcon className="h-4 w-4" />
                  </button>
                  <button
                    onClick={() => removeItem(item.id)}
                    className="ml-2 bg-red-100 text-red-500 rounded-full p-1 hover:bg-red-200 transition-colors duration-200"
                    aria-label="Remove item"
                  >
                    <TrashIcon className="h-4 w-4" />
                  </button>
                </div>
              </div>
            </div>
          ))}

          {/* Shipping and Total Section */}
          <div className="mt-6 bg-light-green-50 rounded-lg p-4">
            <div className="flex justify-between items-center mb-2">
              <span className="text-gray-600">Subtotal:</span>
              <span className="font-semibold">{totalPrice.toFixed(2)} DKK</span>
            </div>

            <div className="flex justify-between items-center mb-2">
              <div className="flex items-center">
                <TruckIcon className="h-5 w-5 text-primary-green mr-2" />
                <span className="text-gray-600">Fragt:</span>
              </div>
              <span className="font-semibold">
                {finalShippingCost === 0
                  ? "Gratis"
                  : `${finalShippingCost.toFixed(2)} DKK`}
              </span>
            </div>

            {enableFreeShipping && (
              <>
                {remainingForFreeShipping > 0 && (
                  <div className="text-sm text-primary-green bg-white rounded-md p-3 mb-2 flex items-center">
                    <TruckIcon className="h-5 w-5 mr-2" />
                    <span>
                      Køb for {remainingForFreeShipping.toFixed(2)} DKK mere for
                      at få gratis fragt!
                    </span>
                  </div>
                )}

                {totalPrice >= freeShippingThreshold && (
                  <div className="text-sm text-primary-green bg-white rounded-md p-3 mb-2 flex items-center">
                    <TruckIcon className="h-5 w-5 mr-2" />
                    <span>Du har opnået gratis fragt!</span>
                  </div>
                )}
              </>
            )}

            <div className="flex justify-between items-center text-xl font-bold mt-4 pt-2 border-t border-gray-300">
              <span>Total:</span>
              <span className="text-primary-green">
                {(totalPrice + finalShippingCost).toFixed(2)} DKK
              </span>
            </div>
          </div>

          {/* Navigation Button */}
          <div className="mt-6 flex justify-end items-center">
            <span className="text-lg md:text-lg font-semibold text-primary-green mr-4">
              Gå til levering
            </span>
            <RoundButton
              onClick={handleNextStep}
              ariaLabel="Fortsæt til levering"
              className="bg-green-button-color text-cosmic-latte hover:bg-opacity-90"
              icon={ArrowRightIcon}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default CartReview;
