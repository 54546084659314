import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  TruckIcon,
  ShoppingBagIcon,
  CreditCardIcon,
  UserIcon,
  MapPinIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/outline";
import logo from "../../assets/images/nytlogo.png";
import RoundButton from "./RoundButton";

const OrderConfirmation = ({
  shippingInfo,
  cartItems,
  totalPrice,
  shippingCost,
  totalWithShipping,
  onPayParams,
  handlePreviousStep,
}) => {
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  const formatAddress = (address) => {
    return `${address.address}, ${address.postalCode} ${address.city}`;
  };

  return (
    <div className="bg-cosmic-latte p-6 rounded-lg shadow-md">
      <h2 className="text-3xl font-semibold text-primary-green mb-8 text-center">
        Ordreoversigt og Betaling
      </h2>

      <div className="grid md:grid-cols-2 gap-8 mb-8">
        <div className="flex items-center justify-center p-6">
          <img
            src={logo}
            alt="We Love Birds Logo"
            className="max-w-full h-auto max-h-48"
          />
        </div>

        <section className="p-6 rounded-lg border border-primary-green">
          <h3 className="text-xl font-semibold text-primary-green mb-4 flex items-center">
            <TruckIcon className="h-6 w-6 mr-2 text-primary-green" />
            Leveringsoplysninger
          </h3>
          <div className="space-y-2">
            <p className="flex items-center">
              <UserIcon className="h-5 w-5 mr-2 text-primary-green" />
              {shippingInfo.fullName}
            </p>
            <p className="flex items-center">
              <MapPinIcon className="h-5 w-5 mr-2 text-primary-green" />
              {formatAddress(shippingInfo)}
            </p>
            <p className="flex items-center">
              <EnvelopeIcon className="h-5 w-5 mr-2 text-primary-green" />
              {shippingInfo.email}
            </p>
          </div>
        </section>

        {!shippingInfo.sameAsBilling && (
          <section className="p-6 rounded-lg border border-primary-green md:col-span-2">
            <h3 className="text-xl font-semibold text-primary-green mb-4 flex items-center">
              <CreditCardIcon className="h-6 w-6 mr-2 text-primary-green" />
              Faktureringsadresse
            </h3>
            <p className="flex items-center">
              <MapPinIcon className="h-5 w-5 mr-2 text-primary-green" />
              {formatAddress({
                address: shippingInfo.billingAddress,
                postalCode: shippingInfo.billingPostalCode,
                city: shippingInfo.billingCity,
              })}
            </p>
          </section>
        )}

        <section className="p-6 rounded-lg border border-primary-green md:col-span-2">
          <h3 className="text-xl font-semibold text-primary-green mb-4 flex items-center">
            <ShoppingBagIcon className="h-6 w-6 mr-2 text-primary-green" />
            Din ordre
          </h3>
          <div className="space-y-4">
            {cartItems.map((item) => (
              <div
                key={item.id}
                className="flex items-center justify-between py-2 border-b border-gray-200"
              >
                <div className="flex items-center">
                  <img
                    src={item.imageUrl}
                    alt={item.name}
                    className="w-16 h-16 object-cover rounded-md mr-4"
                  />
                  <div>
                    <span className="font-medium">{item.name}</span>
                    <span className="text-gray-500 block">
                      x {item.quantity}
                    </span>
                  </div>
                </div>
                <span className="font-semibold">
                  {(item.price * item.quantity).toFixed(2)} DKK
                </span>
              </div>
            ))}
            <div className="pt-4 mt-4 border-t border-gray-300">
              <div className="flex justify-between items-center text-gray-600">
                <span>Subtotal</span>
                <span>{totalPrice.toFixed(2)} DKK</span>
              </div>
              <div className="flex justify-between items-center text-gray-600">
                <span>Forsendelse</span>
                <span>{shippingCost.toFixed(2)} DKK</span>
              </div>
              <div className="flex justify-between items-center font-semibold text-lg mt-2 pt-2 border-t border-gray-200">
                <span>Total</span>
                <span className="text-primary-green">
                  {totalWithShipping.toFixed(2)} DKK
                </span>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Opdateret sektion starter her */}
      <div className="mt-8">
        <div className="flex items-center justify-center sm:justify-end mb-4">
          <input
            type="checkbox"
            id="terms"
            checked={isTermsAccepted}
            onChange={(e) => setIsTermsAccepted(e.target.checked)}
            className="w-4 h-4 text-green-button-color bg-gray-100 border-gray-300 rounded focus:ring-green-button-color"
          />
          <label
            htmlFor="terms"
            className="ml-2 text-xs sm:text-sm text-gray-700"
          >
            Jeg accepterer{" "}
            <Link
              to="/betingelser"
              className="text-primary-green hover:underline font-bold"
              target="_blank"
              rel="noopener noreferrer"
            >
              handelsbetingelserne
            </Link>
          </label>
        </div>
        <div className="flex flex-wrap justify-between items-center">
          <div className="flex items-center mb-4 sm:mb-0">
            <RoundButton
              onClick={handlePreviousStep}
              ariaLabel="Tilbage til levering"
              className="bg-gray-200 text-primary-green hover:bg-gray-300"
              icon={TruckIcon}
            />
            <span className="ml-4 text-sm md:text-lg font-semibold text-primary-green">
              Tilbage
            </span>
          </div>

          {onPayParams && (
            <form
              method="post"
              action="https://onpay.io/window/v3/"
              acceptCharset="UTF-8"
              className="flex items-center"
              onSubmit={(e) => {
                if (!isTermsAccepted) {
                  e.preventDefault();
                  alert(
                    "Du skal acceptere handelsbetingelserne for at fortsætte."
                  );
                }
              }}
            >
              <input
                type="hidden"
                name="onpay_gatewayid"
                value={onPayParams.onpay_gatewayid}
              />
              <input
                type="hidden"
                name="onpay_currency"
                value={onPayParams.onpay_currency}
              />
              <input
                type="hidden"
                name="onpay_amount"
                value={onPayParams.onpay_amount}
              />
              <input
                type="hidden"
                name="onpay_reference"
                value={onPayParams.onpay_reference}
              />
              <input
                type="hidden"
                name="onpay_accepturl"
                value={onPayParams.onpay_accepturl}
              />
              <input
                type="hidden"
                name="onpay_callbackurl"
                value={onPayParams.onpay_callbackurl}
              />
              <input
                type="hidden"
                name="onpay_declineurl"
                value={onPayParams.onpay_declineurl}
              />
              <input
                type="hidden"
                name="onpay_hmac_sha1"
                value={onPayParams.onpay_hmac_sha1}
              />

              <span className="mr-4 text-sm md:text-lg font-semibold text-primary-green">
                Betal
              </span>
              <RoundButton
                type="submit"
                ariaLabel={`Betal ${(
                  Number(onPayParams.onpay_amount) / 100
                ).toFixed(2)} ${onPayParams.onpay_currency}`}
                className={`${
                  isTermsAccepted
                    ? "bg-green-button-color hover:bg-opacity-90"
                    : "bg-gray-400 cursor-not-allowed"
                } text-cosmic-latte transition-colors duration-200`}
                icon={CreditCardIcon}
                disabled={!isTermsAccepted}
              />
            </form>
          )}
        </div>
      </div>
      {/* Opdateret sektion slutter her */}
    </div>
  );
};

export default OrderConfirmation;
