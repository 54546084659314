// ProductCard.js
import React from "react";
import { Link } from "react-router-dom";
import { useCart } from "../../contexts/CartContext";
import addshoppingbagIcon from "../../assets/icons/addshoppingbagicon.png";
import addIcon from "../../assets/icons/addicon.png";

const ProductCard = ({ product }) => {
  const { dispatch } = useCart();

  const handleAddToCart = (e) => {
    e.preventDefault();
    dispatch({
      type: "ADD_ITEM",
      payload: {
        id: product._id,
        name: product.name,
        price: product.price,
        imageUrl: product.imageUrl,
      },
    });
  };

  return (
    <Link to={`/product/${product._id}`} className="group">
      <div className="bg-light-green-50 rounded-2xl shadow-md overflow-hidden transition-all duration-300 transform hover:-translate-y-2 hover:shadow-xl">
        <div className="relative">
          <img
            src={product.imageUrl}
            alt={product.name}
            className="w-full h-60 md:h-72 object-cover transition-transform duration-300 group-hover:scale-105"
          />
          <div className="absolute inset-0 bg-primary-green bg-opacity-20 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center">
            <button
              onClick={handleAddToCart}
              className="w-12 h-12 bg-green-button-color rounded-full flex items-center justify-center hover:bg-opacity-90 transition-all duration-200 transform translate-y-4 group-hover:translate-y-0 shadow-lg"
              aria-label="Add to cart"
            >
              <div className="w-[95%] h-[95%] flex items-center justify-center">
                <img
                  src={addshoppingbagIcon}
                  alt="Add to cart"
                  className="w-full h-full object-contain"
                />
              </div>
            </button>
          </div>
        </div>
        <div className="p-4">
          <div className="flex justify-between items-start mb-2">
            <h3 className="text-base md:text-lg font-semibold text-primary-green truncate">
              {product.name}
            </h3>
            <span className="text-base md:text-lg text-green-button-color font-bold ml-2">
              {product.price} DKK
            </span>
          </div>
          <p className="text-sm text-gray-600 mb-3 truncate">
            {product.category}
          </p>
          <div className="flex items-center justify-between">
            <span className="text-sm text-primary-green font-medium">
              Se detaljer
            </span>
            <div className="w-7 h-7 bg-cosmic-latte rounded-full flex items-center justify-center">
              <div className="w-[95%] h-[95%] flex items-center justify-center">
                <img
                  src={addIcon}
                  alt="See details"
                  className="w-full h-full object-contain"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ProductCard;
