import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LoginPage = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = () => {
    loginWithRedirect();
  };

  const handleSignUp = () => {
    loginWithRedirect({ screen_hint: "signup" });
  };

  return (
    <div className="max-w-md mx-auto mt-10">
      <div className="bg-cosmic-latte shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <h2 className="text-2xl mb-6 text-center">Velkommen</h2>

        <div className="flex flex-col space-y-4">
          <button
            onClick={handleLogin}
            className="bg-blue-500 hover:bg-blue-700 text-cosmic-latte font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Log Ind
          </button>

          <button
            onClick={handleSignUp}
            className="bg-green-500 hover:bg-green-700 text-cosmic-latte font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Opret Konto
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
