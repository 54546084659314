import React, { useEffect, useState } from 'react';
import sanityClient from '../services/sanityClient';

// Import custom icons
import emailIcon from '../assets/icons/emailicon.png';
import addressIcon from '../assets/icons/addressicon.png';
import phoneIcon from '../assets/icons/phoneicon.png';
import responseTimeIcon from '../assets/icons/responsetimeicon.png';

const ContactCard = ({ icon, label, children }) => (
  <div className="bg-white/80 backdrop-blur-sm rounded-xl shadow-lg p-6 hover:shadow-xl transition-shadow duration-300">
    <div className="flex items-center space-x-4">
      <div className="p-0.5 bg-light-green-50 rounded-full flex items-center justify-center w-14 h-14">
        <img src={icon} alt={label} className="w-12 h-12 object-contain" />
      </div>
      <div>
        <h2 className="text-lg font-medium text-primary-green mb-2">{label}</h2>
        {children}
      </div>
    </div>
  </div>
);

function ContactPage() {
  const [contactInfo, setContactInfo] = useState(null);

  useEffect(() => {
    const fetchContactInfo = async () => {
      try {
        const data = await sanityClient.fetch(`*[_type == "contactInfo"][0]`);
        setContactInfo(data);
      } catch (error) {
        console.error('Error fetching contact info:', error);
      }
    };

    fetchContactInfo();
  }, []);

  if (!contactInfo) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-pulse text-primary-green">Indlæser...</div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="max-w-3xl mx-auto">
        {/* Hero Section */}
        <div className="bg-gradient-to-br from-light-green-50 to-cosmic-latte rounded-3xl shadow-lg p-8 mb-8 text-center">
          <h1 className="text-4xl md:text-5xl font-bold text-primary-green mb-3">
            {contactInfo.mainHeading}
          </h1>
          <p className="text-xl text-forest-green">{contactInfo.subHeading}</p>
        </div>

        {/* Contact Information Grid */}
        <div className="grid md:grid-cols-2 gap-6">
          <ContactCard icon={emailIcon} label={contactInfo.email.label}>
            <a 
              href={`mailto:${contactInfo.email.value}`} 
              className="text-forest-green hover:text-primary-green transition-colors"
            >
              {contactInfo.email.value}
            </a>
          </ContactCard>

          <ContactCard icon={phoneIcon} label={contactInfo.phone.label}>
            <a 
              href={`tel:${contactInfo.phone.value}`} 
              className="text-forest-green hover:text-primary-green transition-colors"
            >
              {contactInfo.phone.value}
            </a>
          </ContactCard>

          <ContactCard icon={responseTimeIcon} label={contactInfo.phoneHours.label}>
            <div className="space-y-1">
              {contactInfo.phoneHours.value.map((time, index) => (
                <p key={index} className="text-gray-600">
                  <span className="font-medium text-primary-green">{time.day}:</span>{' '}
                  {time.hours}
                </p>
              ))}
            </div>
          </ContactCard>

          <ContactCard icon={addressIcon} label={contactInfo.address.label}>
            <address className="not-italic text-gray-600">
              <p>{contactInfo.address.street}</p>
              <p>{contactInfo.address.postalCode} {contactInfo.address.city}</p>
            </address>
          </ContactCard>
        </div>
      </div>
    </div>
  );
}

export default ContactPage;