import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import {
  CogIcon,
  UserIcon,
  PencilIcon,
  ShoppingBagIcon,
  ArrowLeftIcon,
} from "@heroicons/react/24/outline";
import UserInfo from "../components/Profile/UserInfo";
import UserPosts from "../components/Profile/UserPosts";
import PurchaseHistory from "../components/Profile/PurchaseHistory";
import DeleteAccount from "../components/Profile/DeleteAccount";
import UsernameGenerator from "../components/Profile/UsernameGenerator";
import { updateUser, fetchUser } from "../services/api";
import { fetchPurchaseHistory } from "../services/purchaseService";

const ProfilePage = () => {
  const { user, isAuthenticated, isLoading, logout } = useAuth0();
  const [activeTab, setActiveTab] = useState("info");
  const [showAdditionalSettings, setShowAdditionalSettings] = useState(false);
  const [username, setUsername] = useState("");
  const [purchaseHistory, setPurchaseHistory] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const loadUserData = async () => {
      if (user?.sub) {
        try {
          const userData = await fetchUser(user.sub);
          if (userData && userData.username) {
            setUsername(userData.username);
          } else {
            setUsername(user.nickname || user.name);
          }
        } catch (error) {
          console.error("Error loading user data:", error);
          setUsername(user.nickname || user.name);
        }
      }
    };

    if (isAuthenticated && !isLoading) {
      loadUserData();
    }
  }, [user, isAuthenticated, isLoading]);

  useEffect(() => {
    const loadPurchaseHistory = async () => {
      if (user?.sub) {
        try {
          const history = await fetchPurchaseHistory(user.sub);
          setPurchaseHistory(history);
        } catch (error) {
          console.error("Error loading purchase history:", error);
        }
      }
    };

    if (isAuthenticated && !isLoading) {
      loadPurchaseHistory();
    }
  }, [user, isAuthenticated, isLoading]);

  if (isLoading) {
    return <div className="text-center py-10">Indlæser profil...</div>;
  }

  if (!isAuthenticated) {
    return (
      <div className="text-center py-10 text-red-500">
        Venligst log ind for at se din profil.
      </div>
    );
  }

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
    navigate("/");
  };

  const handleSaveUsername = async (newUsername) => {
    try {
      await updateUser(user.sub, { username: newUsername });
      setUsername(newUsername);
      console.log("Nyt brugernavn gemt:", newUsername);
    } catch (error) {
      console.error("Fejl ved opdatering af brugernavn:", error);
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const renderMobileTabButton = (tabName, icon, label) => (
    <button
      onClick={() => setActiveTab(tabName)}
      className={`flex flex-col items-center justify-center transition-colors duration-200 ${
        activeTab === tabName ? "text-green-button-color" : "text-gray-500"
      }`}
    >
      <div
        className={`w-14 h-14 rounded-full flex items-center justify-center mb-1 ${
          activeTab === tabName ? "bg-green-button-color" : "bg-gray-200"
        }`}
      >
        {React.cloneElement(icon, {
          className: `h-6 w-6 ${
            activeTab === tabName ? "text-white" : "text-gray-600"
          }`,
        })}
      </div>
      <span className="text-xs mt-1">{label}</span>
    </button>
  );

  const renderDesktopTabButton = (tabName, label) => (
    <button
      onClick={() => setActiveTab(tabName)}
      className={`px-4 py-2 rounded-lg transition-colors duration-200 ${
        activeTab === tabName
          ? "bg-green-button-color text-cosmic-latte"
          : "bg-gray-200 text-gray-700 hover:bg-gray-300"
      }`}
    >
      {label}
    </button>
  );

  return (
    <div className="container mx-auto px-4 py-8 md:max-w-[80%]">
      <div className="bg-cosmic-latte rounded-lg shadow-lg overflow-hidden">
        <div className="bg-light-green-50 p-6 flex justify-between items-center">
          <div className="flex items-center">
            <button
              onClick={handleGoBack}
              className="mr-4 w-10 h-10 bg-primary-green text-cosmic-latte rounded-full flex items-center justify-center hover:bg-opacity-90 transition-colors duration-200"
              aria-label="Gå tilbage"
            >
              <ArrowLeftIcon className="h-6 w-6" />
            </button>
            <h1 className="text-2xl md:text-3xl font-bold text-primary-green">
              Min Profil
            </h1>
          </div>
          <button
            onClick={() => setShowAdditionalSettings(!showAdditionalSettings)}
            className="border-2 border-primary-green text-primary-green px-4 py-2 rounded-lg hover:bg-primary-green hover:text-cosmic-latte transition-colors duration-200 flex items-center justify-center"
          >
            <span className="hidden md:inline mr-3">
              Yderligere indstillinger
            </span>
            <CogIcon className="h-5 w-5" />
          </button>
        </div>
        <div className="p-6">
          <div className="mb-6">
            <h2 className="text-xl font-semibold text-primary-green mb-2">
              Dit Fugle-brugernavn
            </h2>
            <UsernameGenerator
              initialUsername={username}
              onSaveUsername={handleSaveUsername}
            />
          </div>

          {/* Mobile tab buttons */}
          <div className="flex mb-6 space-x-4 md:hidden justify-around">
            {renderMobileTabButton("info", <UserIcon />, "Profiloplysninger")}
            {renderMobileTabButton("posts", <PencilIcon />, "Mine Opslag")}
            {renderMobileTabButton(
              "purchases",
              <ShoppingBagIcon />,
              "Købshistorik"
            )}
          </div>

          {/* Desktop tab buttons */}
          <div className="hidden md:flex mb-6 space-x-4">
            {renderDesktopTabButton("info", "Profiloplysninger")}
            {renderDesktopTabButton("posts", "Mine Opslag")}
            {renderDesktopTabButton("purchases", "Købshistorik")}
          </div>

          <div>
            {activeTab === "info" && (
              <UserInfo user={user} preferredUsername={username} />
            )}
            {activeTab === "posts" && <UserPosts userId={user.sub} />}
            {activeTab === "purchases" && <PurchaseHistory userId={user.sub} />}
          </div>

          <div className="mt-8 flex justify-between items-center">
            <button
              onClick={handleLogout}
              className="bg-primary-green text-cosmic-latte px-6 py-3 rounded-lg hover:bg-opacity-90 transition-colors duration-200 text-lg font-semibold"
            >
              Log ud
            </button>
            {showAdditionalSettings && <DeleteAccount />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;