import React, { useState, useMemo } from "react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";
import {
  UserIcon,
  MapPinIcon,
  PhoneIcon,
  EnvelopeIcon,
  CalendarIcon,
} from "@heroicons/react/24/outline";
import intetBillede from "../../assets/images/intetbillede.png";

const UserPostCard = ({ post, onDelete, canDelete }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showContact, setShowContact] = useState(false);

  const {
    title = "",
    featuredImageUrl,
    images = [],
    description = "",
    price = "",
    contactInfo = {},
    createdAt,
  } = post || {};

  const allImages = useMemo(() => {
    const imageArray = [featuredImageUrl, ...images].filter(Boolean);
    return imageArray.length > 0 ? imageArray : [intetBillede];
  }, [featuredImageUrl, images]);

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % allImages.length);
  };

  const prevImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + allImages.length) % allImages.length
    );
  };

  const toggleContact = () => {
    setShowContact((prevState) => !prevState);
  };

  const handleDelete = async () => {
    if (window.confirm("Er du sikker på, at du vil slette dette opslag?")) {
      onDelete?.();
    }
  };

  const randomRotation = useMemo(() => Math.random() * 2 - 1, []);

  if (!post) return null;

  const contactFields = [
    { icon: UserIcon, value: contactInfo.name, label: "Navn" },
    {
      icon: MapPinIcon,
      value:
        contactInfo.city && contactInfo.zipCode
          ? `${contactInfo.city}, ${contactInfo.zipCode}`
          : null,
      label: "Lokation",
    },
    { icon: PhoneIcon, value: contactInfo.phone, label: "Telefon" },
    { icon: EnvelopeIcon, value: contactInfo.email, label: "Email" },
  ].filter((field) => field.value);

  return (
    <div className="relative mt-16 mx-auto w-full max-w-4xl md:max-w-5xl lg:max-w-6xl">
      <div
        className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full z-10 flex items-center justify-center"
        style={{
          width: "3rem",
          height: "3rem",
          backgroundColor: "#2d836a",
          boxShadow: "0 3px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          className="bg-cosmic-latte rounded-full"
          style={{ width: "1.8rem", height: "1.8rem" }}
        ></div>
      </div>

      <div
        className="bg-cosmic-latte rounded-xl shadow-xl overflow-hidden flex flex-col relative"
        style={{
          border: "0.5rem solid #E6F4EA",
          transform: `rotate(${randomRotation}deg)`,
          transition: "transform 0.3s ease-in-out",
          width: "calc(100% - 2rem)",
          margin: "0 auto",
        }}
      >
        <div className="p-6 flex flex-col md:flex-row">
          <div className="md:w-1/2 mb-6 md:mb-0 md:mr-6">
            <div className="relative w-full pb-[100%] md:pb-[66.67%]">
              <img
                src={allImages[currentImageIndex]}
                alt={title}
                className="absolute top-0 left-0 w-full h-full object-cover rounded-xl shadow-md"
              />
              <button
                onClick={prevImage}
                className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-cosmic-latte rounded-full p-2 shadow-md hover:bg-gray-100 transition-colors duration-200"
              >
                <ChevronLeftIcon className="h-5 w-5 text-gray-600" />
              </button>
              <button
                onClick={nextImage}
                className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-cosmic-latte rounded-full p-2 shadow-md hover:bg-gray-100 transition-colors duration-200"
              >
                <ChevronRightIcon className="h-5 w-5 text-gray-600" />
              </button>
            </div>
            <div className="flex mt-4 space-x-2 overflow-x-auto">
              {allImages.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`${title} - ${index + 1}`}
                  className={`w-20 h-20 object-cover rounded-lg cursor-pointer transition-all duration-200 ${
                    index === currentImageIndex
                      ? "border-3 border-primary-green"
                      : "border border-gray-200 hover:border-primary-green"
                  }`}
                  onClick={() => setCurrentImageIndex(index)}
                />
              ))}
            </div>
          </div>

          <div className="md:w-1/2">
            <h2 className="text-3xl font-bold text-primary-green mb-3">
              {title}
            </h2>
            <p className="text-2xl font-semibold text-[#2d836a] mb-4">
              {price}
            </p>

            <div
              className="prose prose-lg overflow-y-auto flex-grow"
              style={{ maxHeight: "350px" }}
            >
              <p className="text-gray-700 whitespace-pre-wrap">{description}</p>
            </div>
          </div>
        </div>

        <div className="px-6 pb-6">
          <div className="flex justify-between items-center space-x-2">
            <button
              onClick={toggleContact}
              className="flex-1 bg-green-button-color text-cosmic-latte px-6 py-3 rounded-xl hover:bg-opacity-90 transition-colors duration-200 text-lg font-semibold"
            >
              {showContact ? "Skjul kontakt" : "Vis kontakt"}
            </button>

            {canDelete && (
              <button
                onClick={handleDelete}
                className="bg-red-500 text-white p-3 rounded-xl hover:bg-red-600 transition-colors duration-200"
                title="Slet opslag"
              >
                <TrashIcon className="h-6 w-6" />
              </button>
            )}
          </div>
        </div>

        <div
          className={`bg-light-green-50 border-t-4 border-primary-green transition-all duration-300 ease-in-out overflow-hidden ${
            showContact ? "max-h-96 opacity-100" : "max-h-0 opacity-0"
          }`}
        >
          <div className="p-6">
            <h3 className="text-xl font-semibold text-primary-green mb-4">
              Kontaktoplysninger
            </h3>
            <div className="space-y-2">
              {contactFields.map((field, index) => (
                <div key={index} className="flex items-center">
                  <field.icon className="h-5 w-5 text-primary-green mr-3" />
                  <p className="text-gray-800">{field.value}</p>
                </div>
              ))}
            </div>
            {createdAt && (
              <div className="flex items-center text-sm text-gray-600 mt-4">
                <CalendarIcon className="h-4 w-4 mr-2" />
                Oprettet: {new Date(createdAt).toLocaleDateString()}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPostCard;
