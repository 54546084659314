import React from 'react';
import { Link } from 'react-router-dom';
import SideNavigation from './SideNavigation';
import TopRight from './TopRight';
import logoImage from '../../assets/images/welovebirdsskriftlogo.png';
import newLogo from '../../assets/images/nytlogo.png';
import styled from 'styled-components';

const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 30;
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  position: relative;
  height: 4rem;

  @media (min-width: 768px) {
    height: auto;
    padding: 1rem;
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 51%;
  max-height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 768px) {
    position: static;
    transform: none;
    width: auto;
    max-height: none;
  }
`;

const NewLogo = styled.img`
  width: 2.5rem;
  height: 1.9rem;
  object-fit: contain;

  @media (min-width: 768px) {
    display: none;
  }
`;

const MainLogo = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;

  @media (min-width: 768px) {
    position: fixed;
    top: 40px;
    left: 0;
    right: 0;
    height: 84px;
    max-width: 49%;
    margin: auto;
  }
`;

const MobileNewLogo = styled(Link)`
  z-index: 2;

  @media (min-width: 768px) {
    display: none;
  }
`;

const TopRightContainer = styled.div`
  z-index: 2;
`;

const NavigationContainer = styled.div`
  @media (max-width: 767px) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
  }

  @media (min-width: 768px) {
    position: fixed;
    top: 24px;
    left: 0;
    bottom: 0;
    width: auto;
  }
`;

function Header() {
  return (
    <HeaderContainer>
      <HeaderContent>
        <MobileNewLogo to="/">
          <NewLogo src={newLogo} alt="We Love Birds Logo" />
        </MobileNewLogo>
        <LogoContainer>
          <MainLogo src={logoImage} alt="We Love Birds" />
        </LogoContainer>
        <TopRightContainer>
          <TopRight />
        </TopRightContainer>
      </HeaderContent>
      <NavigationContainer>
        <SideNavigation />
      </NavigationContainer>
    </HeaderContainer>
  );
}

export default Header;