import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="container mx-auto px-4 py-8 text-center">
      <h1 className="text-4xl font-bold text-primary-green mb-4">404 - Siden blev ikke fundet</h1>
      <p className="text-lg mb-8">Beklager, men den side du leder efter eksisterer ikke.</p>
      <Link to="/" className="bg-green-button-color text-cosmic-latte px-6 py-3 rounded-lg hover:bg-opacity-90 transition-colors duration-200">
        Gå til forsiden
      </Link>
    </div>
  );
};

export default NotFound;