import React from "react";
import {
  UserIcon,
  EnvelopeIcon,
  CalendarIcon,
} from "@heroicons/react/24/outline";

const UserInfo = ({ user, preferredUsername }) => {
  return (
    <div className="bg-cosmic-latte rounded-lg shadow-md p-6 mb-6">
      <h2 className="text-2xl font-semibold text-primary-green mb-4">
        Profiloplysninger
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="flex items-center">
          <UserIcon className="h-6 w-6 text-green-button-color mr-2" />
          <span className="font-semibold mr-2">Brugernavn:</span>
          {preferredUsername || user.nickname || "Ikke angivet"}
        </div>
        <div className="flex items-center">
          <EnvelopeIcon className="h-6 w-6 text-green-button-color mr-2" />
          <span className="font-semibold mr-2">Email:</span>
          {user.email}
        </div>
        {user.created_at && (
          <div className="flex items-center">
            <CalendarIcon className="h-6 w-6 text-green-button-color mr-2" />
            <span className="font-semibold mr-2">Medlem siden:</span>
            {new Date(user.created_at).toLocaleDateString()}
          </div>
        )}
      </div>
    </div>
  );
};

export default UserInfo;
