import React from 'react';
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/24/outline";

const RoundButton = ({ onClick, ariaLabel, className, icon: Icon, direction = 'right' }) => {
  const baseClasses = "w-12 h-12 rounded-full flex items-center justify-center transition-colors duration-200";
  const combinedClasses = `${baseClasses} ${className}`;

  return (
    <button
      onClick={onClick}
      className={combinedClasses}
      aria-label={ariaLabel}
    >
      {Icon ? (
        <Icon className="h-6 w-6" />
      ) : (
        direction === 'right' ? (
          <ChevronRightIcon className="h-6 w-6" />
        ) : (
          <ChevronLeftIcon className="h-6 w-6" />
        )
      )}
    </button>
  );
};

export default RoundButton;