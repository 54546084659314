// src/services/productService.js

export const fetchProducts = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/products`);
    if (!response.ok) {
      throw new Error('Failed to fetch products');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching products:', error);
    throw new Error(`Failed to fetch products: ${error.message}`);
  }
};

export const fetchProductById = async (id) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/products/${id}`);
    if (!response.ok) {
      throw new Error('Failed to fetch product');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching product:', error);
    throw new Error(`Failed to fetch product: ${error.message}`);
  }
};