import React, { useEffect, useState } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { XCircleIcon, ExclamationTriangleIcon, ArrowPathIcon, HomeIcon } from '@heroicons/react/24/outline';
import Lottie from 'lottie-react';
import errorAnimation from '../assets/images/error-animation.json';

const PaymentFailed = () => {
  const { orderNumber } = useParams();
  const location = useLocation();
  const [errorDetails, setErrorDetails] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const errorCode = queryParams.get('onpay_errorcode');
    
    const getErrorMessage = (code) => {
      switch (code) {
        case '1000':
          return 'Betalingen blev afbrudt';
        case '2000':
          return 'Betalingen blev afvist af din bank';
        case '3000':
          return 'Der opstod en teknisk fejl under behandlingen';
        default:
          return 'Der opstod en fejl under betalingen';
      }
    };

    setErrorDetails({
      orderNumber,
      errorCode,
      message: getErrorMessage(errorCode),
      amount: queryParams.get('onpay_amount'),
      currency: queryParams.get('onpay_currency') === '208' ? 'DKK' : queryParams.get('onpay_currency')
    });
  }, [orderNumber, location.search]);

  return (
    <div className="container mx-auto px-4 py-4 sm:py-8 max-w-3xl">
      <div className="bg-cosmic-latte p-4 sm:p-8 rounded-lg shadow-lg">
        <div className="flex flex-col items-center mb-4 sm:mb-8">
          {/* Mindre animation på mobil */}
          <div className="w-40 h-40 sm:w-64 sm:h-64 mb-4 sm:mb-6">
            <Lottie 
              animationData={errorAnimation}
              loop={true}
              style={{ width: '100%', height: '100%' }}
            />
          </div>
          <div className="text-center">
            <h1 className="text-2xl sm:text-3xl font-bold text-red-600 mb-2">
              Betaling Mislykkedes
            </h1>
            <div className="flex items-center justify-center text-red-500 mb-4">
              <ExclamationTriangleIcon className="h-5 w-5 sm:h-6 sm:w-6 mr-2" />
              <span className="text-base sm:text-lg">Der opstod en fejl med din betaling</span>
            </div>
          </div>
        </div>
        
        {errorDetails && (
          <div className="mb-4 sm:mb-8">
            <div className="bg-cosmic-latte p-4 sm:p-6 rounded-lg shadow-inner space-y-3 sm:space-y-4">
              <div className="flex items-center justify-between border-b border-gray-200 pb-3 sm:pb-4">
                <span className="text-sm sm:text-base text-gray-600">Ordrenummer:</span>
                <span className="text-sm sm:text-base font-semibold">{errorDetails.orderNumber}</span>
              </div>
              {errorDetails.amount && (
                <div className="flex items-center justify-between border-b border-gray-200 pb-3 sm:pb-4">
                  <span className="text-sm sm:text-base text-gray-600">Beløb:</span>
                  <span className="text-sm sm:text-base font-semibold">
                    {(parseInt(errorDetails.amount) / 100).toLocaleString('da-DK', {
                      style: 'currency',
                      currency: errorDetails.currency
                    })}
                  </span>
                </div>
              )}
              <div className="flex items-center justify-between">
                <span className="text-sm sm:text-base text-gray-600">Fejlbesked:</span>
                <span className="text-sm sm:text-base font-semibold text-red-600">{errorDetails.message}</span>
              </div>
            </div>
          </div>
        )}
        
        <div className="text-center mb-4 sm:mb-8">
          <p className="text-base sm:text-lg text-gray-700">
            Ingen penge er blevet trukket fra din konto
          </p>
          <p className="text-sm sm:text-base text-gray-600 mt-2">
            Du er velkommen til at prøve igen eller vende tilbage senere
          </p>
        </div>
        
        <div className="flex justify-center space-x-8 sm:space-x-12">
          <Link
            to="/checkout"
            className="flex flex-col items-center group"
          >
            <div className="w-14 h-14 sm:w-16 sm:h-16 bg-green-button-color text-cosmic-latte rounded-full flex items-center justify-center mb-2 transform transition-all duration-200 hover:scale-110 hover:shadow-lg">
              <ArrowPathIcon className="h-7 w-7 sm:h-8 sm:w-8" />
            </div>
            <span className="text-sm sm:text-base text-gray-700 font-medium group-hover:text-green-button-color transition-colors duration-200">
              Prøv igen
            </span>
          </Link>
          
          <Link
            to="/"
            className="flex flex-col items-center group"
          >
            <div className="w-14 h-14 sm:w-16 sm:h-16 bg-gray-500 text-white rounded-full flex items-center justify-center mb-2 transform transition-all duration-200 hover:scale-110 hover:shadow-lg">
              <HomeIcon className="h-7 w-7 sm:h-8 sm:w-8" />
            </div>
            <span className="text-sm sm:text-base text-gray-700 font-medium group-hover:text-gray-500 transition-colors duration-200">
              Gå til forsiden
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PaymentFailed;