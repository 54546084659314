import React from 'react';
import { Link } from 'react-router-dom';

const BlogPostMagnet = ({ post }) => {
  const randomRotation = Math.random() * 6 - 3; // Random rotation between -3 and 3 degrees

  return (
    <Link to={`/blog/${post.slug.current}`} className="block transform transition-all duration-300 hover:scale-105">
      <div className="relative pt-4 max-w-sm mx-auto"> {/* Added max-width and centered */}
        {/* Magnet */}
        <div 
          className="absolute top-0 left-1/2 transform -translate-x-1/2 w-9 h-9 rounded-full z-20"
          style={{
            backgroundColor: '#2d836a',
            boxShadow: '0 3px 5px rgba(0,0,0,0.1)'
          }}
        ></div>
        
        <div 
          className="relative bg-cosmic-latte rounded-xl shadow-lg overflow-hidden z-10"
          style={{ 
            transform: `rotate(${randomRotation}deg)`,
            transformOrigin: 'center',
            width: '100%',
            height: '450px', // Height already reduced in previous update
          }}
        >
          {/* Image */}
          <img 
            src={post.imageUrl} 
            alt={post.title}
            className="w-full h-[375px] object-cover"
          />

          {/* Title */}
          <div className="bg-cosmic-latte p-4 h-[75px] flex items-center justify-center">
            <h2 className="text-primary-green text-lg font-semibold text-center line-clamp-2">{post.title}</h2>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default BlogPostMagnet;