import { createClient } from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

const client = createClient({
  projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
  dataset: 'production',
  useCdn: false,
  apiVersion: "2024-06-24",
  token: process.env.REACT_APP_SANITY_SECRET_TOKEN 
});

// Set up the image URL builder
const builder = imageUrlBuilder(client)

// Helper function to generate image URLs
const imageUrl = (source) => builder.image(source)

export { client as default, imageUrl }