import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import CartMenu from "./../Cart/CartMenu";
import { useCart } from "../../contexts/CartContext";
import userAccountIcon from "../../assets/icons/userAccountIcon.png";
import guestAccountIcon from "../../assets/icons/guestAccountIcon.png";
import addshoppingbagIcon from "../../assets/icons/addshoppingbagicon.png";
import shoppingbagIcon from "../../assets/icons/shoppingbagIcon.png";

function TopRight() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const { state } = useCart();

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const toggleCart = () => setIsCartOpen(!isCartOpen);

  const cartItemsCount = state.items.reduce(
    (total, item) => total + item.quantity,
    0
  );

  // Vælg det rigtige ikon baseret på om der er items i kurven
  const cartIcon = cartItemsCount > 0 ? shoppingbagIcon : addshoppingbagIcon;

  return (
    <div className="flex items-center space-x-2 sm:space-x-4">
      <button
        onClick={isAuthenticated ? toggleMenu : loginWithRedirect}
        className="relative"
      >
        <div
          className={`relative w-8 h-8 sm:w-10 sm:h-10 md:w-12 md:h-12 ${
            isAuthenticated ? "border-2 border-primary-green rounded-full" : ""
          }`}
        >
          <div className="absolute inset-0 bg-light-green-50 rounded-full"></div>
          <div className="absolute inset-0.5 bg-cosmic-latte rounded-full shadow-md hover:shadow-xl transition-shadow duration-300 overflow-hidden">
            <img
              src={isAuthenticated ? userAccountIcon : guestAccountIcon}
              alt={isAuthenticated ? "User Account" : "Guest Account"}
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      </button>
      <button onClick={toggleCart} className="relative">
        <div className="relative w-8 h-8 sm:w-10 sm:h-10 md:w-12 md:h-12">
          <div className="absolute inset-0 bg-light-green-50 rounded-full"></div>
          <div className="absolute inset-0.5 bg-cosmic-latte rounded-full shadow-md hover:shadow-xl transition-shadow duration-300 overflow-hidden">
            <img
              src={cartIcon}
              alt="Shopping Bag"
              className="w-full h-full object-cover"
            />
          </div>
          {cartItemsCount > 0 && (
            <span className="absolute -top-1 -right-1 bg-red-500 text-cosmic-latte rounded-full w-4 h-4 sm:w-5 sm:h-5 flex items-center justify-center text-[10px] sm:text-xs">
              {cartItemsCount}
            </span>
          )}
        </div>
      </button>

      {/* User menu */}
      {isMenuOpen && isAuthenticated && (
        <div className="absolute right-0 top-full mt-2 w-48 bg-cosmic-latte rounded-md shadow-lg py-1 z-50">
          <Link
            to="/profile"
            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            onClick={toggleMenu}
          >
            Konto
          </Link>
          <button
            onClick={() => {
              logout({ returnTo: window.location.origin });
              toggleMenu();
            }}
            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          >
            Log ud
          </button>
        </div>
      )}

      {/* Cart Menu */}
      <CartMenu
        isOpen={isCartOpen}
        onClose={toggleCart}
        className="absolute right-0 top-full mt-2 z-50"
      />
    </div>
  );
}

export default TopRight;
