import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { PortableText } from "@portabletext/react";
import zooCertificateImage from "../assets/images/zooCertifikat.png";

function HomePage() {
  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/homepage`)
      .then((response) => response.json())
      .then((data) => setPageData(data))
      .catch((error) => console.error("Error:", error));
  }, []);

  if (!pageData) return <div>Loading...</div>;

  return (
    <div className="container mx-auto px-4 relative min-h-screen">
      {/* Velkomstsektion - Øget bredde med md:max-w-4xl */}
      <section className="text-center mt-32 mb-16 mx-auto w-full md:max-w-4xl">
        <h2 className="text-4xl font-bold text-primary-green mb-8">
          {pageData.welcomeSection.heading}
        </h2>
        <div className="bg-light-green-50 p-6 rounded-lg">
          <p className="text-lg text-primary-green">
            {pageData.welcomeSection.subheading}
          </p>
        </div>
      </section>

      {/* Dynamiske sektioner */}
      <div className="flex flex-col items-center mb-20">
        {pageData.sections &&
          pageData.sections.map((section, index) => (
            <section key={index} className="my-16 max-w-2xl w-full text-center">
              <h3 className="text-2xl font-bold text-primary-green mb-6">
                {section.heading}
              </h3>
              <div className="prose prose-lg mx-auto">
                <PortableText value={section.content} />
              </div>
            </section>
          ))}
      </div>

      {/* Zoo Certificate Section - Rykket længere ned med md:bottom-[-150px] */}
      <div className="md:absolute relative bottom-4 left-1/2 transform -translate-x-1/2 w-3/4 max-w-sm md:left-4 md:transform-none md:w-1/4 md:max-w-xs text-center md:text-left md:bottom-[-150px]">
        <p className="mb-2 text-sm md:text-base text-primary-green">
          We Love Birds støtter{" "}
          <a
            href="https://www.zoo.dk/stoet-verdens-dyr/doner"
            target="_blank"
            rel="noopener noreferrer"
            className="font-bold hover:underline"
          >
            Zoos arbejde
          </a>{" "}
          med at passe på verdens natur og bevare truede dyrearter.
        </p>

        <img
          src={zooCertificateImage}
          alt="Zoo Certificate"
          className="w-full h-auto rounded-lg shadow-lg"
        />
      </div>
    </div>
  );
}

export default HomePage;