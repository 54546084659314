import React from "react";
import { useCart } from "../../contexts/CartContext";
import { Link } from "react-router-dom";
import { XMarkIcon, PlusIcon, MinusIcon } from "@heroicons/react/24/outline";
import shoppingbagIcon from "../../assets/icons/shoppingbagIcon.png";

const CartMenu = ({ isOpen, onClose }) => {
  const { state, dispatch } = useCart();

  const totalPrice = state.items.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );

  const updateQuantity = (id, newQuantity) => {
    if (newQuantity === 0) {
      dispatch({ type: "REMOVE_ITEM", payload: id });
    } else {
      dispatch({
        type: "UPDATE_QUANTITY",
        payload: { id, quantity: newQuantity },
      });
    }
  };

  if (!isOpen) return null;

  return (
    <div className="absolute right-0 top-full mt-0 w-72 bg-cosmic-latte rounded-lg shadow-xl z-50">
      <div className="p-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold text-primary-green">
            Din indkøbskurv
          </h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <XMarkIcon className="h-6 w-6" />
          </button>
        </div>
        {state.items.length === 0 ? (
          <p className="text-gray-500">Din kurv er tom</p>
        ) : (
          <>
            {state.items.map((item) => (
              <div
                key={item.id}
                className="flex justify-between items-center mb-2"
              >
                <div>
                  <p className="font-semibold">{item.name}</p>
                  <p className="text-sm text-gray-500">{item.price} DKK</p>
                </div>
                <div className="flex items-center">
                  <button
                    onClick={() => updateQuantity(item.id, item.quantity - 1)}
                    className="text-gray-500 hover:text-gray-700"
                  >
                    <MinusIcon className="h-4 w-4" />
                  </button>
                  <span className="mx-2">{item.quantity}</span>
                  <button
                    onClick={() => updateQuantity(item.id, item.quantity + 1)}
                    className="text-gray-500 hover:text-gray-700"
                  >
                    <PlusIcon className="h-4 w-4" />
                  </button>
                </div>
              </div>
            ))}
            <div className="mt-4 pt-4 border-t border-gray-200">
              <div className="flex justify-between items-center mb-4">
                <span className="font-semibold">Total:</span>
                <span className="font-semibold">
                  {totalPrice.toFixed(2)} DKK
                </span>
              </div>
              <div className="flex justify-center">
                <Link
                  to="/checkout"
                  className="relative w-12 h-12 bg-green-button-color rounded-full flex items-center justify-center hover:bg-opacity-90 transition-colors duration-200 shadow-md"
                  onClick={onClose}
                >
                  <div className="w-[95%] h-[95%] flex items-center justify-center">
                    <img
                      src={shoppingbagIcon}
                      alt="Checkout"
                      className="w-full h-full object-contain"
                    />
                  </div>
                </Link>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CartMenu;
