export const createUser = async (userData) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    });
    if (!response.ok) {
      throw new Error("Failed to create user");
    }
    return await response.json();
  } catch (error) {
    console.error("Error creating/fetching user:", error);
    throw new Error("Failed to create/fetch user");
  }
};

export const updateUser = async (auth0Id, updates) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/users/${auth0Id}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updates),
      }
    );
    if (!response.ok) {
      throw new Error("Failed to update user");
    }
    return await response.json();
  } catch (error) {
    console.error("Error updating user:", error);
    throw new Error("Failed to update user");
  }
};

export const fetchUser = async (auth0Id) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/users/${auth0Id}`
    );
    if (!response.ok) {
      throw new Error("Failed to fetch user");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching user:", error);
    throw new Error("Failed to fetch user");
  }
};

export const deleteUser = async (auth0Id) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/users/${auth0Id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to delete user");
    }

    return await response.json();
  } catch (error) {
    console.error("Error deleting user:", error);
    throw error;
  }
};
