// UserPostsPage.js
import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { PlusIcon } from "@heroicons/react/24/solid";
import UserPostCard from "../components/UserPosts/UserPostCard";
import CreateUserPostForm from "../components/UserPosts/CreateUserPostForm";
import { fetchUserPosts, deleteUserPost } from "../services/userPostService";

function UserPostsPage() {
  const [userPosts, setUserPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const { isAuthenticated, loginWithRedirect, user } = useAuth0();

  const loadUserPosts = async () => {
    try {
      setLoading(true);
      const posts = await fetchUserPosts();
      setUserPosts(posts || []);
      setError(null);
    } catch (err) {
      console.error("Error fetching user posts:", err);
      setError(err.message || "Der opstod en fejl ved indlæsning af opslag");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadUserPosts();
  }, []);

  const handlePostCreated = () => {
    loadUserPosts();
    setShowCreateForm(false);
  };

  const handlePostDeleted = async (postId) => {
    try {
      await deleteUserPost(postId);
      await loadUserPosts();
    } catch (error) {
      console.error("Error deleting post:", error);
      setError("Der opstod en fejl ved sletning af opslaget");
    }
  };

  if (loading) {
    return (
      <div className="text-center py-10">
        <div className="inline-block animate-spin rounded-full h-8 w-8 border-b-2 border-primary-green"></div>
        <p className="mt-2 text-primary-green">Indlæser brugeropslag...</p>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-light-green-50 rounded-lg shadow-md p-6 mb-8">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
          <h1 className="text-3xl font-bold text-primary-green mb-4 sm:mb-0">
            Undulat Markedsplads
          </h1>
          {isAuthenticated ? (
            <button
              onClick={() => setShowCreateForm(!showCreateForm)}
              className="bg-green-button-color text-cosmic-latte px-4 py-2 rounded-lg hover:bg-opacity-90 transition-colors duration-200 flex items-center mt-4 sm:mt-0"
            >
              <PlusIcon className="h-5 w-5 mr-2" />
              {showCreateForm ? "Annuller" : "Opret nyt opslag"}
            </button>
          ) : (
            <button
              onClick={() => loginWithRedirect()}
              className="bg-green-button-color text-cosmic-latte px-4 py-2 rounded-lg hover:bg-opacity-90 transition-colors duration-200 mt-4 sm:mt-0"
            >
              Log ind for at oprette opslag
            </button>
          )}
        </div>
        <p className="mt-4 text-gray-600">
          Velkommen til Undulat Markedspladsen! Her kan du købe, sælge eller
          bytte alt relateret til undulater.
        </p>
      </div>

      {error && (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-8"
          role="alert"
        >
          <strong className="font-bold">Fejl! </strong>
          <span className="block sm:inline">{error}</span>
        </div>
      )}

      {showCreateForm && (
        <div className="mb-8">
          <CreateUserPostForm onPostCreated={handlePostCreated} />
        </div>
      )}

      <div className="space-y-8">
        {userPosts.length === 0 ? (
          <div className="text-center py-10 bg-cosmic-latte rounded-lg shadow-md">
            <p className="text-xl text-primary-green">
              Der er ingen opslag at vise i øjeblikket.
            </p>
            {isAuthenticated && (
              <p className="mt-2 text-gray-600">
                Vær den første til at oprette et opslag!
              </p>
            )}
          </div>
        ) : (
          userPosts.map((post) => (
            <UserPostCard
              key={post._id}
              post={post}
              onDelete={() => handlePostDeleted(post._id)}
              canDelete={isAuthenticated && user?.sub === post.userId}
            />
          ))
        )}
      </div>
    </div>
  );
}

export default UserPostsPage;
